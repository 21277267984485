<template>
  <v-avatar
    :style="cssProps"
    :size="computedSize"
    :color="colorBackground"
    class="deck-avatar"
    v-bind="$attrs"
  >
    <figure
      :aria-label="name"
      class="deck-avatar__figure"
    >
      <span
        v-show="renderInitials"
        class="deck-avatar__initials"
        aria-hidden="true"
      >
        {{ initials }}
      </span>
      <deck-icon
        v-show="renderIcon"
        :name="icon"
        size="0.5em"
        kind="solid"
        aria-hidden="true"
        class="deck-avatar__icon"
      />
      <img
        v-show="renderImage"
        :src="imageSrc"
        alt=""
        aria-hidden="true"
        @error="renderImageFallback = true"
      >
    </figure>
  </v-avatar>
</template>

<script>
import { stableNumericHash } from '~/assets/javascript/utils/string';
import { camelCase, kebabCase } from 'lodash';
import DeckIcon from '~/deck/icon';
import { PRIMARY_COLORS } from '~/assets/javascript/constants';
import { TRANSLUCENT_BLACK } from '../utils/color';

/**
 * A distinctive avatar component to represent a Persona (users, persons, or user groups).
 * @component
 */
export default {
  name: 'DeckAvatar',

  components: {
    DeckIcon,
  },

  props: {
    /**
     * The name of the Persona represented by the Avatar (required for generating initials and aria-label).
     * @type {string}
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * A unique identifier used to randomly choose a consistent color for the avatar.
     * @type {string}
     */
    uid: {
      type: String,
      default: undefined,
    },

    /**
     * An image Path or URL to display in the avatar. Will render on top of icon and initials if defined.
     * @type {string}
     */
    imageSrc: {
      type: String,
      default: undefined,
    },

    /**
     * A Font Awesome icon to display in the avatar. Will render on top of initials if defined.
     * @type {string}
     */
    icon: {
      type: String,
      default: '',
    },

    /**
     * The size of the avatar.
     * @type {'small' | 'medium' | 'large' | string}
     * @default 'medium'
     */
    size: {
      type: String,
      default: 'medium',
      validator: value => ['small', 'medium', 'large'].includes(value),
    },

    /**
     * The background color for the avatar. Will override the random color set by the `uid` prop if defined.
     * @type { 'blue' | 'cyan' | 'teal' | 'green' | 'yellow' | 'orange' | 'red' | 'pink' | 'deep-purple' | 'grey' | string}
     */
    color: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      renderImageFallback: false,
    };
  },

  computed: {
    cssProps() {
      return {
        '--deck-avatar-size': this.computedSize,
        '--deck-avatar-color': TRANSLUCENT_BLACK,
      };
    },
    computedSize() {
      return new Map([
        ['small', '20px'],
        ['medium', '32px'],
        ['large', '48px'],
      ]).get(this.size) || this.size;
    },
    colorBackground() {
      if (!this.color && this.uid) {
        // Choose a random color consistently based on a unique identifier
        const colorIndex = stableNumericHash(this.uid, PRIMARY_COLORS.length);

        return `${kebabCase(PRIMARY_COLORS[colorIndex])}-lighten-4`;
      }

      return PRIMARY_COLORS.includes(camelCase(this.color))
        ? `${this.color}-lighten-4` // Explicitly set color
        : this.color || 'grey-lighten-4'; // Default color if none set
    },
    renderImage() {
      return !!this.imageSrc && !this.renderImageFallback;
    },
    renderIcon() {
      return !this.renderImage && !!this.icon;
    },
    renderInitials() {
      return !this.renderImage && !this.renderIcon;
    },
    initials() {
      const words = this.name.split(' ');
      const allInitials = words.map(word => word.charAt(0).toUpperCase());

      return allInitials.slice(0, 2).join('');
    },
  },
};
</script>

<style lang="scss">
.deck-avatar.v-avatar.v-avatar { // Override Vuetify's default avatar styles in multiple nested possibilities where it's originally defined
  width: var(--deck-avatar-size) !important;
  height: var(--deck-avatar-size) !important;
  min-width: var(--deck-avatar-size) !important;
  min-height: var(--deck-avatar-size) !important;
  font-size: var(--deck-avatar-size) !important; // Initials will be proportional to the avatar size with em unit
  color: var(--deck-avatar-color) !important;
}

.deck-avatar__figure {
  display: grid !important;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: inherit;

  > * {
    grid-area: 1/-1; // https://ishadeed.com/article/less-absolute-positioning-modern-css/
  }

  img {
    height: inherit;
    width: inherit;
  }
}
.deck-avatar__icon {
  // When an icon is defined, it should be rendered on top of the initials and hide them with the same background color as the parent
  background-color: inherit !important; // v-icon override
}

.deck-avatar__initials {
  font-size: 0.5em;
  color: inherit;
  font-weight: 600;
  letter-spacing: 0.04em;
}
</style>
