/**
 * Returns a stable numeric hash for a given value for simple hashing purposes.
 * Based on the djb2 algorithm. @see http://www.cse.yorku.ca/~oz/hash.html
 *
 * Keep in mind that the smaller the range, or larger the input, the more likely
 * it is that there will be collisions for different inputs (ie. results will be
 * the same for different inputs).
 *
 * (!) This is not a cryptographically secure hash.
 *
 * @param {string|number} value - The value to be hashed.
 * @param {number} [returnRange] - An optional range of the returned hash. Eg. 255 will return a numeric hash between 0 and 254. Defaults to JavaScript's maximum safe integer if not provided.
 * @returns {number} The stable numeric hash.
 */
export const stableNumericHash = (value, returnRange = Number.MAX_SAFE_INTEGER) => {
  let hash = 5381;

  value
    .toString()
    .split('')
    .forEach((char) => {
      hash = ((hash << 5) + hash) + char.charCodeAt(0); // eslint-disable-line no-bitwise
    });

  hash = Math.abs(hash);

  if (returnRange) {
    hash %= returnRange;
  }

  return hash;
};
